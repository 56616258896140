import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container mt-5">
      <h1 className="text-main fw-bolder">Privacy Policy for OurBride</h1>
      <p>
        <strong>Last updated: June 28, 2024</strong>
      </p>

      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use, and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>

      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>

      <h2>Interpretation and Definitions</h2>

      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        plural.
      </p>

      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </li>
        <li>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by, or is under common control with a party, where
          "control" means ownership of 50% or more of the shares, equity
          interest, or other securities entitled to vote for election of
          directors or other managing authority.
        </li>
        <li>
          <strong>Application</strong> refers to Our Bride, the software program
          provided by the Company.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to OurBride.
        </li>
        <li>
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device, or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </li>
        <li>
          <strong>Country</strong> refers to: Egypt.
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone, or a digital tablet.
        </li>
        <li>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Service</strong> refers to the Application or the Website or
          both.
        </li>
        <li>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service, or to assist the
          Company in analyzing how the Service is used.
        </li>
        <li>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </li>
        <li>
          <strong>Website</strong> refers to OurBride, accessible from{" "}
          <a
            href="https://www.our-bride.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.our-bride.com
          </a>
          .
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </ul>

      <h2>Collecting and Using Your Personal Data</h2>

      <h3>Types of Data Collected</h3>

      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>Usage Data</li>
      </ul>

      <h4>Usage Data</h4>
      <p>
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as Your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
      </p>

      <h3>Information Collected while Using the Application</h3>
      <p>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </p>
      <ul>
        <li>Information regarding your location</li>
        <li>Information from your Device's phone book (contacts list)</li>
        <li>
          Pictures and other information from your Device's camera and photo
          library
        </li>
      </ul>
      <p>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </p>

      <h3>Tracking Technologies and Cookies</h3>
      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information.
      </p>

      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          <strong>To provide and maintain our Service</strong>, including to
          monitor the usage of our Service.
        </li>
        <li>
          <strong>To manage Your Account</strong>: to manage Your registration
          as a user of the Service.
        </li>
        <li>
          <strong>For the performance of a contract</strong>: the development,
          compliance, and undertaking of the purchase contract for products,
          items, or services You have purchased.
        </li>
        <li>
          <strong>To contact You</strong>: by email, phone, or other equivalent
          forms of electronic communication.
        </li>
      </ul>

      <h2>Retention of Your Personal Data</h2>
      <p>
        The Company will retain Your Personal Data only for as long as necessary
        for the purposes set out in this Privacy Policy.
      </p>

      <h2>Disclosure of Your Personal Data</h2>
      <p>We may disclose Your Personal Data in the following situations:</p>
      <ul>
        <li>
          <strong>Business Transactions:</strong> If the Company is involved in
          a merger, acquisition, or asset sale.
        </li>
        <li>
          <strong>Law enforcement:</strong> If required by law or in response to
          valid requests by public authorities.
        </li>
        <li>
          <strong>Other legal requirements:</strong> To protect against legal
          liability.
        </li>
      </ul>

      <h2>Security of Your Personal Data</h2>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet is 100% secure.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        We do not knowingly collect personally identifiable information from
        anyone under the age of 13. If You are a parent or guardian and You are
        aware that Your child has provided Us with Personal Data, please contact
        Us.
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update Our Privacy Policy from time to time. You are advised to
        review this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, You can contact us
        at: <a href="mailto:ourbride.com@gmail.com">ourbride.com@gmail.com</a>.
      </p>
    </div>
  );
}
